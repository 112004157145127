<template lang="pug">
vs-sidebar.items-no-padding(
  position-right="",
  v-model="active",
  parent="body",
  default-index="55555551",
  :click-not-close="true"
)
  .h-100(v-if="isLoadingCustomer || isUpdatingCustomer || isDeletingCustomer || isLoadingPoolsList")
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        |
        | Chargement des détails du client...
  .h-full(v-else-if="customer")
    .sidebar-header.py-1.px-2
      .d-flex.align-items-center
        div
          h3 {{  customer.firstName+&apos; &apos;+customer.lastName  }}
          small {{ customer.activity }}
      feather-icon.close-icon.cursor-pointer(
        icon="XIcon",
        size="24",
        @click.stop="active = !active"
      )
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center
        b-button.btn-icon(
          v-if="archived",
          variant="warning",
          @click="restoreCustomerLocal(customer.id, customer.firstName + ' ' + customer.lastName)"
        )
          feather-icon.mr-50(icon="ArrowDownIcon")
          | Restaurer
        b-button.btn-icon(
          v-else="",
          variant="primary",
          @click="editCustomer(customer.id)",
          :disabled="archived"
        )
          feather-icon.mr-50(icon="EditIcon")
          | Modifier
      b-button-group
        b-dropdown.bg-outline-primary.btn-group-icon(variant="outline-primary" v-if="!archived")
          template(#button-content) + d'actions
          b-dropdown-item.w-full(@click="createPool(customer.id)") Ajouter un bassin
        b-button.btn-icon(variant="outline-danger" v-if="archived" @click="deleteCustomerLocal(customer.id, customer.firstName + ' ' + customer.lastName)")
          feather-icon.mr-50(icon="TrashIcon")
          | Supprimer
        b-button.btn-icon(v-else variant="outline-danger" @click="archiveCustomerLocal(customer.id, customer.firstName + ' ' + customer.lastName)")
          feather-icon(icon="ArchiveIcon") 
    vs-divider.m-0
    .sidebar-content
      b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols="12", style="padding: 0 !important; height: 100% !important")
          ul.d-flex
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'customer' ? 'current' : ''" @click="tabActive = 'customer'")
                span Client
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'pools' ? 'current' : ''" @click="tabActive = 'pools'")
                span Bassins
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'products' ? 'current' : ''" @click="tabActive = 'products'")
                span Produits
      .customer.mt-1(v-if="tabActive == 'customer'")
        div(v-show="customer.tel2 || customer.tel1 || customer.email")
          h4.mb-1.text-primary
            feather-icon.mr-1(icon="PhoneCallIcon") 
            | Coordonnées
          ul.listTab
            li(v-show="customer.email")
              span Adresse e-mail
              span {{ customer.email }}
            li(v-show="customer.tel1")
              span Tel principal
              span {{ customer.tel1 }}
            li(v-show="customer.tel2")
              span Tel secondaire
              span {{ customer.tel2 }}
            li(v-show="customer.telConcierge")
              span Tel conciergerie
              span {{ customer.telConcierge }}
          vs-divider
        div(v-show="customer.address")
          h4.mb-1.text-primary
            feather-icon.mr-1(icon="MapPinIcon") 
            | Adresse
          p.paraphbox
            span(v-show="customer.address") {{ customer.address }}
            br
            span(v-show="customer.postalCode || customer.city ") {{ customer.postalCode }} {{ customer.city }}
          vs-divider
        div(v-show="customer.indication || customer.contractCreationDate")
          h4.mb-1.text-primary
            feather-icon.mr-1(icon="MessageSquareIcon") 
            | Indication
          ul.listTab
            li(v-show="customer.contractCreationDate")
              span Date du contrat
              span {{ dateConvert(customer.contractCreationDate) }}
            li(v-show="customer.indication")
              span Indication
              span {{ customer.indication }}
            vs-divider
        div(v-show="customer.secondLastName || customer.secondFirstName")
          h4.mb-1.text-primary
            feather-icon.mr-1(icon="MessageSquareIcon") 
            | Second contact
          ul.listTab
            li(v-show="customer.secondCivility || customer.secondLastName || customer.secondFirstName")
              span Nom
              span {{ `${findCivility(customer.secondCivility)} ${customer.secondFirstName ? customer.secondFirstName : ''} ${customer.secondLastName} ` }}
            li(v-show="customer.tel2")
              span Tel
              span {{ customer.tel2 }}
            vs-divider
        div(v-show="customer.conciergeLastName || customer.conciergeFirstName")
          h4.mb-1.text-primary
            feather-icon.mr-1(icon="MessageSquareIcon") 
            | Gardien
          ul.listTab
            li(v-show="customer.conciergeCivility || customer.conciergeLastName || customer.conciergeFirstName")
              span Nom
              span {{ `${findCivility(customer.conciergeCivility)} ${customer.conciergeFirstName ? customer.conciergeFirstName : ''} ${customer.conciergeLastName} ` }}
            li(v-show="customer.telConcierge")
              span Tel
              span {{ customer.telConcierge }}
            vs-divider
      .pools.mt-1(v-if="tabActive == 'pools'")
        div(v-for="pool in pools" :key="pool.id")
          ul.listTab
            li(v-show="pool.label")
              span Nom du bassin
              span {{ pool.label }}
            li(v-show="pool.city")
              span Ville
              span {{ pool.city }}
            li(v-show="pool.address")
              span Adresse
              span {{ pool.address }}
            li(v-show="pool.address")
              span Type de bassin
              span {{ pool.poolType.label }}
            li(v-show="pool.postalCode")
              span Code postal
              span {{ pool.postalCode }}
            li(v-show="pool.length")
              span Longeur
              span {{ pool.length }} m
            li(v-show="pool.width")
              span Largeur
              span {{ pool.width }} m
            li(v-show="pool.depth")
              span Profondeur
              span {{ pool.depth }} cm
            li(v-show="pool.depth && pool.width && pool.length")
              span Volume
              span {{ volume(pool) }} m³
            b-button.btn-icon.mt-1(
              variant="primary",
              @click="editPool(pool.id, pool.label)",
            )
              feather-icon.mr-50(icon="EditIcon")
              | Modifier le bassin
            vs-divider
      .products.mt-1(v-if="tabActive == 'products'")
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Produits utilisés sur une période
        .group-total
          date-range-picker.w-100#dates(
            ref="picker",
            :show-week-numbers="true",
            :timePicker="false",
            :opens="'left'",
            :append-to-body="true",
            :locale-data="config",
            :ranges="defaultRange",
            v-model="filterRange",
            :auto-apply="true"
          )
          p(v-if="productUsedList.length > 0") Total période : <b>{{ formatCurrency(totalProductUsed) }}</b>
        .text-center.flex-center.mt-5(v-if="isLoadingProductUsedList")
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          p(style="transform: translateY(55px)") Chargement de la liste des produits utilisés...
        .text-center.flex-center.mt-5(v-else-if="productUsedList.length == 0")
          feather-icon(icon="InboxIcon", size="52")
          p(style="transform: translateY(5px)") Aucun produit utilisé sur cette période.
        ul.listTab(v-else)
          li(v-for="(productUsed, index) in productUsedList", :key="index")
            span {{ productUsed.label }}
            .d-flex.justify-content-between.w-50
              span {{ productUsed.quantity }} {{ productUsed.unit }}{{ productUsed.quantity < 2 ? "" : "s" }} ({{ formatCurrency(productUsed.price) }})
              span {{ formatCurrency(productUsed.price * productUsed.quantity) }}
</template>


<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { formatCurrency } from "@/utils";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
const defaultRange = {
  "Ce mois-ci": [
    new Date(dayjs().startOf("month").hour(1)),
    new Date(dayjs().endOf("month")),
  ],
  "Mois dernier": [
    new Date(dayjs().subtract(1, "month").startOf("month").hour(1)),
    new Date(dayjs().subtract(1, "month").endOf("month")),
  ],
  "Ce trimestre": [
    new Date(dayjs().startOf("quarter").hour(1)),
    new Date(dayjs().endOf("quarter")),
  ],
  "Trimestre dernier": [
    new Date(dayjs().subtract(1, "quarter").startOf("quarter").hour(1)),
    new Date(dayjs().subtract(1, "quarter").endOf("quarter")),
  ],
  "Cette année": [
    new Date(dayjs().startOf("year").hour(1)),
    new Date(dayjs().endOf("year")),
  ],
  "Année dernière": [
    new Date(dayjs().subtract(1, "year").startOf("year").hour(1)),
    new Date(dayjs().subtract(1, "year").endOf("year")),
  ],
  "Depuis le début": [
    new Date(dayjs("2020-01-01").hour(1)),
    new Date(dayjs().endOf("year")),
  ],
};

export default {
  data() {
    return {
      archived: false,
      customer: {},
      pools: [],
      config: config,
      defaultRange: defaultRange,
      filterRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      tabActive: "customer",
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingCustomer",
      "compagniesList",
      "isLoadingCustomer",
      "isDeletingCustomer",
      "isOpenSidebarCustomer",
      "productUsedList",
      "isLoadingProductUsedList",
      "poolsList",
      "isLoadingPoolsList",
    ]),
    active: {
      get() {
        return this.isOpenSidebarCustomer;
      },
      set(val) {
        this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", val);
      },
    },
    totalProductUsed() {
      let total = 0;
      this.productUsedList.forEach((productUsed) => {
        total += productUsed.price * productUsed.quantity;
      });
      return total;
    },
  },
  methods: {
    ...mapActions([
      "updateCustomer",
      "deleteCustomer",
      "isArchivingCustomer",
      "archiveCustomer",
      "restoreCustomer",
      "fetchCustomer",
      "fetchCustomerArchived",
      "fetchProductUsedOnTimeSpanByCustomerId",
      "fetchPoolsList"
    ]),
    volume(pool) {
      switch (pool.poolType?.shape) {
        case "rectangulaire":
          return Math.ceil(pool.width * pool.length * (pool.depth / 100)) + '';
        case "ronde":
          var radius = pool.width / 2;
          return Math.ceil(Math.PI * Math.pow(radius, 2) * (pool.depth / 100)) + '';
        case "ovale":
          var semiMajorAxis = pool.width / 2;
          var semiMinorAxis = pool.length / 2;
          return Math.ceil(Math.PI * semiMajorAxis * semiMinorAxis * (pool.depth / 100)) + '';
          default: 
            return null
      }
    },
    findCivility(value){
      return value == 0 ? "M." : value == 1 ? "Mme." : value == 3 ? "Soc."  : "Mx."
    },
    formatCurrency,
    dateConvert(datePull) {
      const date = new Date(datePull);
      const jour = date.getDate();
      const mois = date.getMonth() + 1;
      const annee = date.getFullYear();
      const dateFormatee = `${jour < 10 ? "0" + jour : jour}-${mois < 10 ? "0" + mois : mois}-${annee}`;
      return dateFormatee;
    },
    getCustomerArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.fetchCustomerArchived(id).then((res) => {
          this.findPoolsCustomer(res.id)
          this.customer = res;
        });
      } else {
        this.customer = {};
      }
    },
    getCustomerDetails(id) {
      this.archived = false;
      if (id > 0) {
        this.fetchCustomer(id).then((res) => {
          this.findPoolsCustomer(res.id)
          this.customer = res;
        });
      } else {
        this.customer = {};
      }
    },
    findPoolsCustomer(id){
      this.fetchPoolsList().then(()=>{
        this.pools = this.poolsList.filter(elem => elem.customerId === id)
      })
    },
    async editPool(id, label) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
      this.$router.push({
        name: "edit-pool",
        params: {
          id: id,
          title: "Edit : " + label,
          tips: "Editer le bassin : " + label,
        },
      });
    },
    async editCustomer(id) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
      this.$router.push({
        name: "edit-customer",
        params: {
          id: id,
          title:
            "Edit : " + this.customer.firstName + " " + this.customer.lastName,
          tips:
            "Editer le customer : " +
            this.customer.firstName +
            " " +
            this.customer.lastName,
        },
      });
    },
    async createPool(customerId) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
      this.$router.push({
        name: "new-pool",
        params: { customerId: customerId },
      });
    },
    archiveCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCustomer(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
          }
        });
    },
    restoreCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreCustomer(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
          }
        });
    },
    deleteCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteCustomer(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", false);
          }
        });
    },
  },
  components: {
    VuePerfectScrollbar,
    DateRangePicker,
  },
  watch: {
    isOpenSidebarCustomer(val) {
      if (!val) {
        this.filterRange = {
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        };
      }
    },
    filterRange() {
      this.fetchProductUsedOnTimeSpanByCustomerId({
        customerId: this.customer.id,
        startTo: this.filterRange.startDate,
        endTo: this.filterRange.endDate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-scrollable-sticky {
  position: relative;
  padding: 0 !important;
  #nav-sticky-horizontal {
    white-space: nowrap;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      border-bottom: 1px solid var(--gray);
      margin-bottom: 0.7rem;

      li a {
        display: block;
        padding: 0.3rem 1rem;
        color: var(--body-color);
        text-decoration: none;
      }
      li a.disabled {
        color: var(--gray);
        cursor: default !important;
        .material-icons-outlined {
          color: var(--gray) !important;
        }
      }
      li a.current {
        color: var(--primary);
        font-weight: 600;
        position: relative;
        font-size: 14px;
        &:before {
          content: "";
          width: 100%;
          height: 3px;
          position: absolute;
          right: 0px;
          bottom: -3px;
          background: var(--primary) !important;
        }
        .material-icons-outlined {
          color: var(--primary) !important;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .content-scrollable-sticky {
    #nav-sticky-horizontal {
      > ul {
        display: flex;
        width: 100%;
        align-content: center;
        justify-content: center;
        align-items: center;
        li {
          flex: 1;
        }
        li a.current {
          position: relative;
          &:before {
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: -2px;
          }
        }
      }
    }
  }
}
@media (min-height: 300px) {
  #nav-sticky-horizontal ul {
    position: sticky;
    top: 1rem;
  }
}

.group-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  #dates {
    width: 100%;
    .drp-buttons {
      display: none;
    }
  }
  p {
    margin-bottom: 0;
  }
}
span {
  word-break: break-word;
}
.products .listTab li {
  display: flex;
  justify-content: space-between;

  .group-data {
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  span {
    &:not(:first-child) {
      text-align: right;
      width: fit-content !important;
    }
    &:first-child {
      width: auto !important;
      padding-right: 1rem;
    }
  }
}

h4 {
  display: flex;
  align-items: center;
}
</style>

